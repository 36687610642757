
// lib
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import isEqual from 'lodash-es/isEqual'

// app
import config from '@/config'
import UiFormGroup from '@/app/shared/components/ui-form-group.vue'
import Product from '@/app/shared/models/Product'
import ProductService from '@/app/shared/services/ProductService'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'

@Component({
  components: { UiFormGroup }
})
export default class AppArticleProducts extends Vue {
  @Prop({ type: ConstructorComponent }) readonly value!: ConstructorComponent

  config = config
  updated = false

  search: string | null = null
  products: Product[] = []

  get idsProp(): number[] {
    return this.value.attrs.products as number[] | undefined ?? []
  }

  onCLick(id: number): void {
    const index = this.products.findIndex((item => item.id === id))

    if (index !== -1) {
      this.products.splice(index, 1)
    }

    this.emitResult()
  }

  emitResult(): void {
    const result = new ConstructorComponent({
      ...this.value,
      $uuid: this.value.$uuid,
      attrs: {
        products: this.products.map(v => v.id)
      }
    })

    this.$emit('input', result)
  }

  async handleSearchProduct(): Promise<void> {
    if (this.search) {
      const product = await ProductService.fetchByCode(this.search)
      this.products.push(product)

      this.emitResult()
    }
  }

  @Watch('idsProp', { immediate: true })
  async initialProducts(value: number[], oldValue: number[]): Promise<void> {
    if (!value || isEqual(value, oldValue) || this.updated) {
      return
    }

    this.updated = true

    for (let key in value) {
      const product = await ProductService.fetchById(value[key])

      this.products.push(product)
    }
  }
}
